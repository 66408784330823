import { useEffect, useState } from "react";
import { useStreamContext } from "react-activity-feed";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
} from "@chakra-ui/react";
import { StreamUser } from "getstream";

import { useUsers } from "../hooks";
import UsersList from "../components/UsersList";

const FollowingsPage = () => {
  const { client } = useStreamContext();
  const { users } = useUsers();
  const [followings, setFollowings] = useState<StreamUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getFollowings = async () => {
      const userId = users[user_id || ""];
      if (!userId) return navigate(-1);

      setLoading(true);
      setError(false);
      try {
        const followingInfo =
          (await client?.feed("user", userId)?.following())?.results || [];

        const followingsPromises = followingInfo.map(async (following) => {
          const user = await client
            ?.user(following.feed_id.replace("timeline:", ""))
            .get();
          return user as unknown as StreamUser;
        });

        const followings = await Promise.all(followingsPromises);
        setFollowings(followings);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getFollowings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, users]);

  if (loading) {
    return (
      <Box maxW="600px" mx="auto" mt={5} textAlign="center">
        <Spinner size="xl" color="teal.500" />
        <Text mt={4} fontSize="lg" color="#fff">
          Loading followings...
        </Text>
      </Box>
    );
  }

  if (error || !followings.length) {
    return (
      <Box maxW="600px" mx="auto" mt={5} textAlign="center">
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error!</AlertTitle>
            <AlertDescription>
              Unable to load followings. Please try again later.
            </AlertDescription>
          </Box>
        </Alert>
        <Button mt={4} colorScheme="teal" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box maxW="600px" mx="auto" mt={5}>
      <Text
        fontSize="2xl"
        fontWeight="bold"
        mb={5}
        color="#fff"
        textAlign="center"
      >
        Followings
      </Text>
      <UsersList users={followings} loading={loading} />
    </Box>
  );
};

export default FollowingsPage;
